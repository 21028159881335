import React, { FC } from 'react';
import CategoryNav from '../CategoryNav';
import FeaturedGuide from '../FeaturedGuide';
import GuideBlocks from '../GuideBlocks';
import HorizontalBrowser from '../HorizontalBrowser';
import PageHeader from '../PageHeader';
import SquareBrowser from '../SquareBrowser';
import { ITravel } from './Travel.def';

const Travel: FC<ITravel> = ({
  pageHeader,
  travelGuides,
  airTravelGuides,
  latestTravelGuides,
  productReviews,
  featuredGuide,
  categoryNav,
}) => {
  const scrollerSettings = {
    defaultTilesToShow: 5,
    responsive: [
      {
        breakpoint: 1899,
        tilesToShow: 4,
      },
      {
        breakpoint: 1524,
        tilesToShow: 3,
      },
      {
        breakpoint: 1150,
        tilesToShow: 2,
      },
    ],
  };
  return (
    <div className="travelPage">
      <PageHeader {...pageHeader} />
      <CategoryNav {...categoryNav} />
      <SquareBrowser {...travelGuides} scrollerSettings={scrollerSettings} />
      <FeaturedGuide {...featuredGuide} />
      <SquareBrowser {...airTravelGuides} scrollerSettings={scrollerSettings} />
      <HorizontalBrowser {...productReviews} componentName="ReviewTile" />
      <GuideBlocks {...latestTravelGuides} />
    </div>
  );
};

export default Travel;
