import { Link } from 'gatsby';
import React, { FC } from 'react';
import ArrowRightIcon from '../../svgs/ArrowRightIcon';
import { IFeaturedGuide } from './FeaturedGuide.def';
import { processSimpleHTMLContent } from '../../utils/processHTMLContent';
import { createSourceElements, createSrc, createSrcSet } from '../../utils';
import {
  LARGE_DESKTOP_MIN_WIDTH_MQ,
  LARGE_TAB_MIN_WIDTH_MQ,
  MEDIUM_TAB_MIN_WIDTH_MQ,
  MED_DESKTOP_MIN_WIDTH_MQ,
  MOBILE_MAX_WIDTH_MQ,
  SMALL_DESKTOP_MIN_WIDTH_MQ,
  SMALL_TAB_MIN_WIDTH_MQ,
} from '../../constants';

const FeaturedGuide: FC<IFeaturedGuide> = ({
  featuredImage,
  moreLink: { link, text },
  title,
  hasTransparentBackground,
  excerpt,
  variant,
}) => {
  const sourceElements = createSourceElements([
    {
      media: LARGE_DESKTOP_MIN_WIDTH_MQ,
      srcSet: createSrcSet(featuredImage.src || '', [
        {
          options: {
            width: 1900,
            height: 650,
          },
        },
      ]),
    },
    {
      media: MED_DESKTOP_MIN_WIDTH_MQ,
      srcSet: createSrcSet(featuredImage.src || '', [
        {
          options: {
            width: 1420,
            height: 650,
          },
        },
      ]),
    },
    {
      media: SMALL_DESKTOP_MIN_WIDTH_MQ,
      srcSet: createSrcSet(featuredImage.src || '', [
        {
          options: {
            width: 1260,
            height: 650,
          },
        },
      ]),
    },
    {
      media: LARGE_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(featuredImage.src || '', [
        {
          options: {
            width: 1004,
            height: 492,
          },
        },
      ]),
    },
    {
      media: MEDIUM_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(featuredImage.src || '', [
        {
          options: {
            width: 740,
            height: 484,
          },
        },
      ]),
    },
    {
      media: SMALL_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(featuredImage.src || '', [
        {
          options: {
            width: 620,
            height: 405,
          },
        },
      ]),
    },
    {
      media: MOBILE_MAX_WIDTH_MQ,
      srcSet: createSrcSet(featuredImage.src || '', [
        {
          options: {
            width: 700,
          },
        },
      ]),
    },
  ]);

  featuredImage = {
    ...featuredImage,
    src: createSrc(featuredImage.src || ''),
  };
  return (
    <section
      className={`featuredGuide ${
        hasTransparentBackground ? 'transparentBackgroundFeaturedGuide' : ''
      } ${variant === 'white' ? 'featuredGuideWhite' : ''}`}
    >
      {link ? (
        <Link to={link}>
          <figure>
            <picture>
              {sourceElements}
              <img {...featuredImage} loading="lazy" />
            </picture>
          </figure>
        </Link>
      ) : (
        <figure>
          <picture>
            {sourceElements}
            <img {...featuredImage} loading="lazy" />
          </picture>
        </figure>
      )}
      <div className="guideContent">
        <header>
        <h2>{link && <Link to={link}>{title}</Link>}</h2>
        </header>
        {typeof excerpt === 'string' ? processSimpleHTMLContent(excerpt) : <p>{excerpt}</p>}
        {link && (
          <Link to={link}>
            {text}
            <ArrowRightIcon />
          </Link>
        )}
      </div>
    </section>
  );
};

export default FeaturedGuide;
