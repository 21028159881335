import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Seo from '../../components/Seo';
import {
  getArticles,
  getArticle,
  getDefaultSEO,
  getFeaturedImageDataFromWpPost,
} from '../../utils';
import Travel from '../../components/Travel/Travel';
import { IReviewTile } from '../../components/ReviewTile/ReviewTile.def';
import HTPageWrapper from '../../wrappers/HTPageWrapper';
import { HT_PAGE_CATEGORY_HUB_PAGE } from '../../constants';

interface ITravelPageProps {
  travelGuides: Queries.WpPostConnection;
  airTravelGuides: Queries.WpPostConnection;
  latestTravelGuides: Queries.WpPostConnection;
  productReviews: Queries.WpPostConnection;
  featuredGuide: Queries.WpPost;
}

const TravelPage = ({ data, location }: PageProps<ITravelPageProps>) => {
  const { featuredGuide, travelGuides, latestTravelGuides, airTravelGuides, productReviews } = data;
  let latestGuides: Queries.WpPost[] = [];
  let travelPosts: Queries.WpPost[] = [];
  let airTravelPosts: Queries.WpPost[] = [];
  let productReviewsPosts: Queries.WpPost[] = [];

  if (latestTravelGuides && Array.isArray(latestTravelGuides.nodes)) {
    latestGuides = latestTravelGuides.nodes;
  }

  if (travelGuides && Array.isArray(travelGuides.nodes)) {
    travelPosts = travelGuides.nodes;
  }

  if (airTravelGuides && Array.isArray(airTravelGuides.nodes)) {
    airTravelPosts = airTravelGuides.nodes;
  }

  if (productReviews && Array.isArray(productReviews.nodes)) {
    productReviewsPosts = productReviews.nodes;
  }

  const latestCCGuides = getArticles(latestGuides);
  const tarvelArticles = getArticles(travelPosts).map((article) => ({
    ...article,
    category: {
      link: '/travel/page/2/',
      text: 'Travel',
    },
    imageOverlay: true,
  }));
  const airTravelArticles = getArticles(airTravelPosts).map((article) => ({
    ...article,
    category: {
      link: '/travel/page/2/',
      text: 'Travel',
    },
    imageOverlay: true,
  }));

  const productReviewsArticles: IReviewTile[] = getArticles(productReviewsPosts).map(
    (productReview) => {
      let lastIndex = productReview.title.toLowerCase().indexOf('in');

      if (lastIndex === -1) {
        lastIndex = productReview.title.toLowerCase().indexOf('for');
      }

      if (lastIndex === -1) {
        lastIndex = productReview.title.toLowerCase().indexOf('[');
      }

      return {
        thumbnail: productReview.thumbnail,
        title: {
          link: productReview.link,
          text: productReview.title,
        },
      };
    },
  );

  const featured = getArticle(featuredGuide);
  const title = 'Travel';

  return (
    <HTPageWrapper
      category={HT_PAGE_CATEGORY_HUB_PAGE + ', Travel'}
      title={title}
      location={location}
    >
      <Travel
        pageHeader={{
          title: 'Travel',
          breadCrumbs: [
            {
              link: '/',
              text: 'Home',
            },
          ],
        }}
        categoryNav={{
          categories: [
            {
              link: '/travel/airlines/',
              text: 'Airlines',
              class: 'credit-card-button',
            },
            {
              link: '/travel/airports/',
              text: 'Airport / Lounges',
              class: 'credit-card-button',
            },
            {
              link: '/travel/hotels/',
              text: 'Hotels',
              class: 'credit-card-button',
            },
            {
              link: '/travel/car-rentals/',
              text: 'Car Rentals',
              class: 'credit-card-button',
            },
            {
              link: '/travel/cruises/',
              text: 'Cruises',
              class: 'credit-card-button',
            },
            {
              link: '/travel/product-reviews/',
              text: 'Product Reviews',
              class: 'credit-card-button',
            },
          ],
          variant: 'category',
          subtitle: 'Explore our helpful guides covering all areas of travel, written by experts.',
        }}
        productReviews={{
          title: 'Product Reviews',
          seeAllLink: {
            link: '/travel/product-reviews/',
            text: 'See All Reviews',
          },
          cards: productReviewsArticles,
          subtitle:
            'Whether you’re looking for the most secure luggage or the best headphones to keep you entertained on your next flight, our product reviews are the best place to start.',
        }}
        travelGuides={{
          articles: tarvelArticles,
          title: 'Travel Guides',
          moreLink: {
            link: '/travel/page/2/',
            text: 'See All',
          },
          isScrollable: true,
        }}
        airTravelGuides={{
          articles: airTravelArticles,
          title: 'Air Travel Guides',
          moreLink: {
            link: '/travel/airlines/',
            text: 'See All',
          },
          isScrollable: true,
          subTitle:
            'From airline reviews to traveling tips and tricks, our experts provide advice to get you flying like a pro.',
        }}
        featuredGuide={{
          featuredImage: featured.thumbnail,
          moreLink: {
            link: featured.link,
            text: 'Learn More',
          },
          title: featured.title,
          hasTransparentBackground: false,
          variant: 'blue',
          excerpt: featured.excerpt,
        }}
        latestTravelGuides={{
          moreLink: {
            link: '/travel/page/2/',
            text: 'See More',
          },
          variant: 'columns',
          articles: latestCCGuides,
          title: 'Latest Travel Guides',
        }}
      />
    </HTPageWrapper>
  );
};

export default TravelPage;

export const Head = ({ location, data }: PageProps<ITravelPageProps>) => {
  const pageTitle = 'Travel Content - UpgradedPoints.com';
  const socialTitle = 'Best Credit Card Articles, Guides & Reviews - UpgradedPoints.com';
  const ogDescription = ''; // TODO
  const includeCollectionPageType = true;
  const extraBreadcrumbList = [{ name: 'Travel', slug: 'travel' }];
  const featuredPost = data?.featuredGuide;
  const featuredImageData = featuredPost ? getFeaturedImageDataFromWpPost(featuredPost) : null;
  const seo = getDefaultSEO(
    location.pathname,
    pageTitle,
    socialTitle,
    ogDescription,
    includeCollectionPageType,
    extraBreadcrumbList,
  );
  return (
    <Seo
      title={pageTitle}
      wpSeo={seo}
      featuredImageData={featuredImageData}
      isArchivePage={true}
      archiveName={'Travel'}
      uri={'/travel/'}
    />
  );
};

export const query = graphql`
  query TravelQuery {
    latestTravelGuides: allWpPost(
      sort: { date: DESC }
      limit: 6
      filter: { categories: { nodes: { elemMatch: { name: { eq: "Travel" } } } } }
    ) {
      nodes {
        featuredImage {
          node {
            sourceUrl
          }
        }
        title
        uri
        dateGmt
        excerpt
        author {
          node {
            uri
            name
          }
        }
      }
    }
    featuredGuide: wpPost(databaseId: { eq: 345513 }) {
      uri
      title
      excerpt
      featuredImage {
        node {
          caption
          width
          height
          sourceUrl
        }
      }
    }
    travelGuides: allWpPost(
      filter: { databaseId: { in: [339359, 336945, 327179, 334824, 332059, 331669] } }
    ) {
      nodes {
        featuredImage {
          node {
            sourceUrl
          }
        }
        categories {
          nodes {
            name
            id
            parentId
          }
        }
        title
        uri
      }
    }
    airTravelGuides: allWpPost(
      filter: { databaseId: { in: [347722, 340703, 345804, 341624, 340017, 334919] } }
    ) {
      nodes {
        featuredImage {
          node {
            sourceUrl
          }
        }
        categories {
          nodes {
            name
            id
            parentId
          }
        }
        title
        uri
      }
    }
    productReviews: allWpPost(
      filter: { tags: { nodes: { elemMatch: { name: { eq: "Travel Product Reviews" } } } } }
      limit: 8
    ) {
      nodes {
        featuredImage {
          node {
            sourceUrl
          }
        }
        categories {
          nodes {
            name
            id
            parentId
          }
        }
        title
        uri
      }
    }
  }
`;
